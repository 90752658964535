#newsletterheader {
    text-align: center;
    font-size: 30px;
    
}

/* #newsletterdiv {
display: flex;
flex-direction: column;
justify-content: center;
}

#articlebox {
    border: 1px solid black;
    max-width: 30%;
    align-items: center;


} */
#newsletterdiv {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    margin-top: -5%;
  }
.dark-mode #newsletterdiv {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    color: white;

  }
  
  #articlebox {
    /* border: 1px solid black; */
    max-width: 90%;
    margin-top: 10%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);


  }
  
.dark-mode  #articlebox {
    border: 1px solid white;
    max-width: 100%;
    margin-top: 10%;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    box-shadow: 0px 0px 24px rgb(243, 237, 237);



  }
  

  #articlebox:hover {
    background-color: #efedf8;
  }
  
  .dark-mode #articlebox:hover {
    background-color: #efedf8;
    color: black;
    box-shadow: 0px 0px 24px rgba(250, 245, 245, 0.16);

  }