/* 
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');


body {
  margin: 0;
  font-family: 'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


body {
  background-color: rgba(245, 244, 250, 1);
  font-family: 'Mulish', sans-serif;

  
} */

/* Import Tailwind CSS */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

/* Default light mode styles */
:root {
  --bg-color: rgba(245, 244, 250, 1);
  --text-color: #000000;
}

/* Dark mode styles */
.dark-mode {
  --bg-color: #292942;
  --text-color: #070707;
}

/* Apply styles to the body */
body {
  margin: 0;
  font-family: 'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color); /* Apply background color */
  color: var(--text-color); /* Apply text color */
}


#navbarbuttonmobile {
  font-size: 16px;
  margin-bottom: 5%;
  margin-top: 5%;
}

.taxonomy-button {
  font-size: 1rem; /* Adjust as needed */
}
