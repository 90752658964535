/* #tabbedresearchdiv {
    margin-left: auto;
} */

#tabbedresearchdiv {
    display: flex;
    justify-content: space-between; /* Horizontally center the buttons */
    margin-left: auto;
    margin-right: auto; /* Center the entire div horizontally */
    max-width: 600px; /* Optional: Set max-width to prevent the div from spanning the entire page */
    margin-top: 4%;
    margin-bottom: 4%;
    align-items: center;

}


#tabbedresearchbutton {
    margin-right: 2%;
    /* margin-left: 2%; */
    text-align: center;
    font-size: 45px;
    margin-top: 3%;
    margin-bottom: 5%;
    color: rgba(0, 0, 0, 0.2);
    
    

}

.dark-mode #tabbedresearchbutton {
    margin-right: 2%;
    text-align: center;
    font-size: 45px;
    margin-top: 3%;
    margin-bottom: 5%;
    color: white;
    ;

}

#tabbedresearchbutton:hover {
    color: black;
 

}


#tabbedresearchdiv button.active {
    text-decoration: underline;
    color: black;
    background-color: rgba(245, 244, 250, 1);
}

.dark-mode #tabbedresearchdiv button.active {
    text-decoration: underline;
    color: white;
    
    background-color: #282c34;
}

.divider {
    width: 1px; /* Adjust width of the divider */
    background-color: black; /* Adjust color of the divider */
    /* margin: 0 10px;  */
    height: 100px;
    margin-right: 5%;
  }

.dark-mode .divider {
    width: 1px; /* Adjust width of the divider */
    margin: 0 10px; /* Adjust margin between buttons and dividers */
    height: 100px;
    border-color: white;
    background-color: white;
    margin-right: 5%;
    
  } 

  #annualreportheader {
    margin-left: 40%;
    font-size: 25px;
    text-align: center;
  }
 .dark-mode #annualreportheader {
    margin-left: 40%;
    font-size: 25px;
    text-align: center;
    color: white;
  }

  @media only screen and (max-width: 768px) {

    #tabbedresearchdiv {
        display: flex;
        justify-content: space-between; /* Horizontally center the buttons */
        margin-left: auto;
        margin-right: auto; /* Center the entire div horizontally */
        max-width: 90%; /* Optional: Set max-width to prevent the div from spanning the entire page */
        margin-top: 4%;
        margin-bottom: 4%;
        align-items: center;
    
    }

    #tabbedresearchbutton {
        margin-right: 2%;
        /* margin-left: 2%; */
        text-align: center;
        font-size: 25px;
        margin-top: 3%;
        margin-bottom: 5%;
        color: rgba(0, 0, 0, 0.2);
        
        
    
    }

    .divider {
        width: 1px; /* Adjust width of the divider */
        background-color: black; /* Adjust color of the divider */
        /* margin: 0 10px;  */
        height: 50px;
        margin-right: 5%;
      }

  }
  