#articlePage {
    max-width: 60%;
    /* justify-items: center; */
    margin: auto;
}

.article-card {
    padding: 20px;
  }
.dark-mode .article-card {
    padding: 20px;
    color: white;
  }
  
  .article-node {
    margin-bottom: 20px;
  }
  
  .node-id {
    font-weight: bold;
  }
  
  .node-html {
    /* Add your styles for the HTML content here */
    margin: 5%;
  }
  
  .node-image {
    max-width: 100%;
    height: auto;
  }
  
  /* ArticleCard.css */

/* Style for <h1> elements */
.article-node h1 {
    font-size: 30px; /* Adjust font size as needed */
    font-weight: bold;
    margin-bottom: 7%; /* Example margin */
    margin-top: 4%;
    text-align: center;
    
  }
  
  /* Style for <h2> elements */
  .article-node h2 {
    font-size: 24px; /* Adjust font size as needed */
    font-weight: bold;
    margin-bottom: 4%; /* Example margin */
    text-align: center;
    margin-top: 4%;
  }
  .article-node h3 {
    font-size: 22px; /* Adjust font size as needed */
    font-weight: 500;
    margin-bottom: 15px; 
    text-align: center;

  }
  .article-node h4 {
    font-size: 20px; /* Adjust font size as needed */
    font-weight: 300;
    margin-bottom: 15px; 
    /* text-align: center; */

  }
  
  
  /* Style for <p> elements */
  .article-node p {
    font-size: 16px; /* Adjust font size as needed */
    line-height: 1.5; /* Example line height */
    margin-bottom: 10px; /* Example margin */
  }
  .article-node li {
    font-size: 16px; /* Adjust font size as needed */
    line-height: 1.5; /* Example line height */
    margin-bottom: 10px; /* Example margin */
    margin-left: 6%;

  }
  
  /* Style for <img> elements */
  .article-node img {
    max-width: 100%; /* Example maximum width */
    height: auto;
    margin-bottom: 20px; /* Example margin */
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Add more styles as needed */

  .article-node a {
    font-size: 16px;
    /* font-weight: 600; */
    text-align: center;
    margin-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    font-style: italic;
  
  }
  /* .article-node strong {
    font-size: 18px;
    font-weight: 600;
    text-align: center;

  } */

  .article #text {
    margin-top: 5%;
  }

  @media only screen and (max-width: 768px) {

    #articlePage {
      max-width: 100%;
      /* justify-items: center; */
      margin: auto;
  }
  

  }
  