
.hero-container {
    max-width: 1440px;
    height: 1143px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }

.dark-mode .hero-container {
    max-width: 1440px;
    height: 1143px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
  
  .hero-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    
  }
  
  /* .hero-box {
    box-sizing: border-box;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    height: 420px;
    margin: 32px;
  } */
  .hero-box {
    position: relative;
    box-sizing: border-box;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    height: 420px;
    margin: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .hero-small {
    flex-basis: calc((100% - 32px) / 3);
    max-width: calc((100% - 32px) / 3);
    height: 420px;
    background-color: #ffffff;

  }

  .hero-small:hover {
    flex-basis: calc((100% - 32px) / 3);
    max-width: calc((100% - 32px) / 3);
    height: 420px;
    background-color: #A487CF;

  }
  
  .hero-wide {
    flex-basis: calc((100% - 32px) / 1.5); 
    max-width: calc((100% - 32px) / 1.5);
    height: 420px;
  }
  
  .bg-purple {
    background-color: #a487cf;
    color: #ffffff;
  }
  
  .heroboxheaders {
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 16px;
    text-align: center;
  }

  /* .heroboxparagraphs {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
    text-align: center;
    
  }
   */

   /* .heroboxparagraphs {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
  } */

  .heroboxparagraphs {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    /* margin-bottom: auto; */
    /* text-align: center; */
    margin-bottom: 16px;


  }
  
  #homepageherotitle {
    font-size: 45px;
    line-height: 45px;
    margin-bottom: 16px;
    text-align: center;
    align-items: center;  
    padding: 5%;  
    margin-top: 40%;
    z-index: 99999;
  }
 .dark-mode #homepageherotitle {
    font-size: 45px;
    line-height: 45px;
    margin-bottom: 16px;
    text-align: center;
    align-items: center;  
    padding: 5%;  
    /* margin-top: 40%; */
    z-index: 99999;
    color: white;
  }

  #realtimeanalyticsdiv {
    margin-top: 5%;
  }
/* 
  #homepageherobox3 {
    background-color: #A487CF;
  } */
 
  .hero-box-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  
  
  @media only screen and (max-width: 768px) {
    .hero-row {
      flex-direction: column;
      align-items: center;
    }
  
    .hero-box {
      width: 90%;
      margin: 16px 0;
    }
    .hero-box:hover {
      width: 90%;
      margin: 16px 0;
    }
  
    .hero-small {
      flex-basis: 100%;
      max-width: 100%;
    }
    .hero-small:hover {
      flex-basis: 100%;
      max-width: 100%;
    }
  
    .hero-wide {
      flex-basis: 100%;
      max-width: 100%;
    }

    #heroimage2 {
      display: none;
    }

    #realtimeanalyticsdiv {
      margin-top: 30%;
    }

    .heroboxheaders {
      font-size: 22px;
    }

    .heroboxparagraphs {
      font-size: 16px;
    
    }
  }
  