
.project-card {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    width: 90%; 
    height: 100%;
}

.project-card-left {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-card-middle {
    width: 55%;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.project-info-top {
    display: flex;
    align-items: center;
}

#cardName {
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    text-align: center;
    padding: 5px;
}

#cardCategory {
    align-self: flex-start;
    margin-left: 5%;
    border-radius: 20px;
    padding: 5px;
    background-color: #efedf8;
}



#cardSummary {
    margin-top: 10px;
    color: rgba(23, 21, 45, 0.4);
    margin-bottom: 5%;
    margin-top: 5%;
}

.view-more-button {
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    width: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: auto;
}
 
.project-card-right {
    width: 30%;
    display: flex;
    flex-direction: column;
}

.project-card-info {
    padding: 10px;
    border: 1px solid #E6E4EF;
    height: 33%;
    display: flex;
    align-items: center; 
    justify-content: center;
}

.project-card-info p {
    flex: 1; 
}

.project-card-info-right {
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    text-align: center;
    max-width: 35%;
    margin-right: 35% ;
}

.project-logo {
    max-width: 70%;
    max-height: 70%;
    width: auto;
    height: auto;
    background-color: white;
}


.visit-website-link {
    display: flex;
    align-items: center;
  }
  


  /* Styles for mobile screens */
@media (max-width: 768px) {
    .project-card{
        margin-right: auto;
        width: 130%;
        /* margin-left: auto; */
        margin-left: -5%;
        height: 100%;
    }

    .project-card-left {
      width: 100%; /* Change width to full for mobile */
      justify-content: center; /* Center the content */
      margin-right: 15%
    }
  
    .project-card-middle {
      width: 100%; /* Change width to full for mobile */
      align-items: center; /* Center the content */
    }

    #cardName {
        margin-right: 3%;
    }
  
    .project-info-top {
      justify-content: center; /* Center the content */
    }
  
    #cardCategory {
      margin-left: 0; /* Remove left margin for mobile */
      text-align: center;
      padding: 0;
    }

    #categorybubble {
        margin-left: 3%;
        /* display: none; */
    }
  
    #cardSummary {
      text-align: center; /* Center summary text for mobile */
      /* margin-top: -5%; */
    }
  
    .view-more-button {
      width: 60%; /* Adjust button width for mobile */
      height: 100%;
      margin-bottom: 3%;
    }
  
    .project-card-right {
      width: 100%; /* Change width to full for mobile */
      margin-top: 20px;
      padding: 0;
      font-size: 12px;
      text-align: center;
      /* display: none; */
      /* height: 100%; */

    }
  
    .project-card-info {
      justify-content: center; /* Center the content */
      padding: 0;
      
      
    }
  
    .project-card-info-right {
      max-width: 100%; /* Adjust max-width for mobile */
      margin-right: 0; /* Remove right margin for mobile */
      padding: 0;
      font-size: 12px;
      /* display: none; */

    }
  
    .project-logo {
      max-width: 60%; /* Adjust logo size for mobile */
      max-height: 100%;
    }

    /* #bottomofcardcatdiv {
        display: none;
    } */
  }
  