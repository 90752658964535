#search-bar {
    /* max-width: 20%; */
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    

}

.dark-mode #search-bar {
    box-shadow: 0px 0px 5px 1px white;
}

#searchform {
    width: 20%;
}

/* #search-button {
    text-align: center;
    align-items: center;
    justify-content: center;
} */

#search-button {
    display: block; /* Ensure the button takes up the full width */
    margin: 0 auto; /* Auto margin horizontally centers the button */
    background-color: #17152D;
    color: white;
    border-radius: 5px;
    padding: 2%;
  }
  