#dbheaderdiv {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  margin-bottom: -15%;
  margin-top: 10%;
}

#dbheadergrid {
  margin-top: -7%;
  max-height: 50%;
  margin-bottom: -20%;
}

#dbheader {
  margin-top: 5%;
  margin-bottom: -5%;
}

#dbheadertitle {
  color: black;
  text-align: center;

  opacity: 40%;
  font-size: 18px;
  margin-top: 3%;
}
.dark-mode #dbheadertitle {
  color: white;
  text-align: center;

  opacity: 40%;
  font-size: 18px;
  margin-top: 3%;
}

#dbheadersentence {
  text-align: center;

  font-size: 45px;
}
.dark-mode #dbheadersentence {
  text-align: center;

  font-size: 45px;
  color: white;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  justify-content: center;
  margin-left: 20%;
  margin-top: -15%;
}

.triple-grid {
  grid-template-columns: repeat(3, minmax(250px, 1fr));
}

.project-grid-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 90%;
  max-height: 100%;
  margin-left: 10%;
  margin-top: 15%;
  margin-bottom: 15%;
}

#completeprojectgrid {
  margin-top: -10%;
}

#loadingscreen {
  margin-left: 50%;
  margin-top: 5%;
  margin-bottom: 10%;
}

#searchbar {
  width: 20%;
}

#paginationdiv {
  text-align: center;
  margin-bottom: 5%;
  margin-top: -10%;
}

.dark-mode #paginationdiv {
  text-align: center;
  margin-bottom: 5%;
  color: white;
}

#paginationkey {
  margin-right: 2%;
}

#paginationkey:hover {
  background-color: antiquewhite;
  color: red;
}

.active {
  background-color: yellow;
}

#catfiltertags {
  margin-top: -15%;
  z-index: 9999;
  position: absolute;
  left: 0;
  margin-left: 30%;
  width: 100%;
}

#catfiltertagspan {
  border: 1px solid black;
  border-radius: 5px;
  margin-right: 1%;
}

#filteredtagsdiv {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: row;

  margin-bottom: 15%;
}

.no-results-message {
  margin-bottom: 50%;
  text-align: center;
  margin-right: auto;
  font-size: 32px;
}

@media (max-width: 480px) {
  .project-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    justify-content: center;
    margin-top: -15%;
  }

  .triple-grid {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }

  .project-grid-container {
    display: flex;
    justify-content: center;
    max-width: 90%;
    margin-left: 10%;
    margin-top: 15%;
    margin-right: 30%;
  }

  #loadingscreen {
    margin-left: 30%;
    
  }

}
