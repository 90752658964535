.contact-page {
  text-align: center;
  max-width: 1440px;
  margin: 0 auto; 
  padding: 0 20px; 
}
.dark-mode .contact-page {
  text-align: center;
  max-width: 1440px;
  margin: 0 auto; 
  padding: 0 20px; 
  color: white;
}

#contact-title {
  font-size: 45px;
  margin-bottom: 7%;
  margin-top: 5%;
}

.contact-container {
  overflow: hidden;
}

.contact-left-section {
  width: 40%; 
  float: left; 
  display: block;
}

.right-section {
  width: 60%; 
  float: right;
}

.contact-container {
  width: 100%; 
  margin-bottom: 20px;
  display: block;
}

.contact-form {
  width: 60%;
  float: right;
  margin-top: 15%;
}

input[type="text"],
textarea {
  width: 100%; 
  margin-bottom: 10px;
  background-color: rgba(245, 244, 250, 1); /* Same as message input */
  border: 1px solid black;
}
input[type="email"],
textarea {
  width: 100%; 
  margin-bottom: 10px;
  background-color: rgba(245, 244, 250, 1); /* Same as message input */
  border: 1px solid black;
}


.container h2 {
  margin-bottom: 10px;
  background-color: rgba(245, 244, 250, 1);
}

#left-side-title {
  font-size: 32px;
  margin-bottom: 10px;
}

.contact-info {
  display: flex;
  justify-content: space-between;
  padding: 10%;
}

#email-span {
  float: right;
  
}

#submitbutton {
  background-color: #17152D;
  color: white;
  width: 20%;
  border-radius: 5px;
  padding: 2%;
}

#contactpicture {
  margin-left: 10%;
}


@media screen and (max-width: 768px) {

  #submitbutton {
    width: 100%;
  }
  .contact-info {
    display: flex;
    justify-content: space-between;
    padding: 10%;
    flex-direction: column;
    font-size: 12px;
  }

  #contact-title {
    font-size: 32px;
  }

  #left-side-title {
    font-size: 20px;
    width: 100%;
    margin-top: 15%;
  }


 
}