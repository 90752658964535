@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');


.insights-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .section {
    margin-bottom: 40px;
  }
  
 .dark-mode .section {
    margin-bottom: 40px;
    color: white;
  }
  
  .section-title-top {
    font-size: 45px;
    /* font-weight: bold; */
    margin-bottom: 10px;
    text-align: center;
    /* margin: 10%; */
    margin-top: 5%;
    margin-bottom: 10%;
  }
  .section-title-bottom {
    font-size: 45px;
    /* font-weight: bold; */
    margin-bottom: 10px;
    text-align: center;
    margin: 10%;
  }
  
  .section-text {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    font-family: 'Mulish', sans-serif;
  }

  #h2s {
    /* text-align: center; */
    font-size: 25px;
    opacity: 60%;
    margin-bottom: 2%;
  }
  