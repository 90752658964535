#projectCard {
    max-width: 100%;
    /* height: 200px; */
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3%;
    box-shadow: 0px 2px 10px rgba(14, 13, 13, 0.3);

}
.dark-mode #projectCard {
    max-width: 100%;
    /* height: 200px; */
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3%;
    box-shadow: 2px 2px 10px white;

}

#projectCardDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;



}
#projectLogo {
    max-width: auto;
    max-height: auto;
    width: auto;
    height: 100px;
    background-color: white;
}

#productcat {
     border: 1px solid black;
    
    border-radius: 20px;
    padding: 5px;
margin-top: 5%;
margin-bottom: 5%;

}
#projectName {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 1%;
}