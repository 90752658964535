/* #researchMenu {
    margin: auto;
    width: 60%;
    margin-top: 5%;
    align-items: center;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
    text-align: center;

 
   
}

#researchpagetitle {
    text-align: center;
    font-size: 45px;
    margin-top: 3%;
    margin-bottom: 5%;
}
.dark-mode #researchpagetitle {
    text-align: center;
    font-size: 45px;
    margin-top: 5%;
    margin-bottom: 3%;
    color: white;
}

#articletitle {
    margin-top: 5%;
    margin-bottom: 5%;
}
.dark-mode #articletitle {
    margin-top: 5%;
    margin-bottom: 5%;
    color: white;
}

#articlecardtitles {
    margin: auto;
    width: 50%;
    font-size: 25px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);

}
.dark-mode #articlecardtitles {
    margin: auto;
    width: 50%;
    color: white;
    box-shadow: 0px 0px 24px rgb(247, 244, 244);

}

#hrs {
    margin-top: 5%;
  }


@media only screen and (max-width: 768px) {
  


    #researchMenu {
        margin: auto;
        width: 90%;
        margin-top: 5%;
        align-items: center;
        justify-content: center;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
      }
      
      #articletitle {
        margin-top: 5%;
        margin-bottom: 5%;
        width: 100%;
      }
      
      #articlecardtitles {
        margin: auto;
        width: 100%;
      }

      #hrs {
        margin-top: 5%;
      }
      
  
  }
   */
   #researchMenu {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 20px;
    margin: auto;
    width: 80%;
    margin-top: 5%;
    text-align: center;
  }
  
  #researchpagetitle {
    text-align: center;
    font-size: 35px;
    margin-top: 3%;
    margin-bottom: 5%;
    margin-left: 40%;
  }
  
  .dark-mode #researchpagetitle {
    text-align: center;
    font-size: 45px;
    margin-top: 5%;
    margin-bottom: 3%;
    color: white;
  }
  
  #articletitle {
    /* margin-top: 5%; */
    margin-bottom: 1%;
    height: 90px; /* Set a fixed height for titles */
    overflow: hidden; /* Handle overflow if title is too long */
    font-size: 20px;
  }
  
  .dark-mode #articletitle {
    margin-top: 5%;
    margin-bottom: 5%;
    color: white;
  }
  
  #articlecardtitles {
    margin: auto;
    font-size: 25px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
    padding: 20px;
    background-color: white; /* Ensure a white background */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure space between elements */
    height: 400px; /* Set a fixed height for each card */
    width: 400px;

  }
  
  .dark-mode #articlecardtitles {
    color: white;
    box-shadow: 0px 0px 24px rgb(247, 244, 244);
    background-color: #333; /* Dark background for dark mode */
  }
  
  #hrs {
    margin-top: 5%;
  }
  
  #articleImage {
    height: 200px; /* Set a fixed height for images */
    width: 100%;
    object-fit: cover; /* Ensure images cover the area without distortion */
    margin-top: 10%;
  }
  
  @media only screen and (max-width: 768px) {
    #researchMenu {
      grid-template-columns: 1fr; /* Single column layout for small screens */
      width: 90%;
      margin: auto;
    }
  
    #articlecardtitles {
      width: 100%;
      height: auto; /* Allow height to adjust on smaller screens */
    }
  
    #articleImage {
      height: auto; /* Allow image height to adjust on smaller screens */
    }

    #researchpagetitle {
      /* text-align: center; */
      font-size: 20px;
      margin-top: 3%;
      margin-bottom: 5%;
      /* margin-left: 40%;
      margin-right: 40%; */
      /* margin-left: -10%; */
      /* margin-right: 20%; */
      margin-left: 22%;
    }
  }
  