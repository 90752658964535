/* 


.footer-container {
  width: 85%;
  margin: auto;
  background-color: rgba(25, 25, 40, 1);
  padding: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 565px;
}

.footer-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
}

.lottie-column {
  display: flex;
  justify-content: center;
}

.footer-content {
  padding: 20px;
  color: white;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
  right: 0;
}

.footer-links li {
  margin-bottom: 10px;
  text-align: center;
}

.social-media {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

#linkedin,
#twitter,
#medium {
  width: 40px;
  height: 40px;
  margin: 0 10px;
}

#linktree {
  width: 40px;
  height: 40px;
  margin: 0 10px;
  border-radius: 2px;
}

#privacy-terms {
  color: white;
  text-align: center;
}

#privacy-terms a {
  display: block;
}

#copyright {
  text-align: center;
}
 */

 .footer-container {
  width: 85%;
  margin: auto;
  background-color: rgba(25, 25, 40, 1);
  padding: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 400px; /* Set a minimum height for smaller screens */
  margin-top: 5%;
  /* position: absolute;
  bottom: 0; */
}

.footer-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; /* Center align items vertically */
  text-align: center; /* Center align text */
  margin-bottom: 20px; /* Add some space between columns */
}

.lottie-column {
  display: flex;
  justify-content: center;
}

.footer-content {
  padding: 20px;
  color: white;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.social-media {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.social-media a {
  margin: 0 10px; /* Add margin between social media icons */
}

#linkedin,
#twitter,
#medium,
#linktree {
  width: 40px;
  height: 40px;
}

#privacy-terms {
  color: white;
  text-align: center;
}

#privacy-terms a {
  display: block;
}

#copyright {
  text-align: center;
}

/* Media queries for tablet and phone screens */
@media (max-width: 768px) {
  .footer-column {
    flex: 1 0 100%; /* Take full width on smaller screens */
    margin-bottom: 0; /* Remove margin between columns */
  }

  .footer-container {
    padding: 3%; /* Reduce padding for smaller screens */
  }

  .social-media a {
    margin: 0 5px; /* Adjust margin for social media icons */
  }

  #linkedin,
  #twitter,
  #medium,
  #linktree {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .footer-container {
    min-height: 300px; /* Adjust minimum height for smaller screens */
  }

  .footer-content {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  #linkedin,
  #twitter,
  #medium,
  #linktree {
    width: 25px;
    height: 25px;
  }

  .footer-container {
    /* Existing styles */
    position: relative; /* Set the position to relative */
  }
  
  #copyright {
    /* New styles */
    position: absolute; /* Set the position to absolute */
    bottom: 0; /* Align the paragraph to the bottom */
    width: 100%; /* Ensure full width */
    padding: 10px 0; /* Add padding for spacing */
    margin: 0; /* Remove default margin */
    display: none;
  }
  
}
