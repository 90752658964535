

body {
  overflow-x: hidden;
}



.container {
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  margin: auto;
  /* overflow-x: hidden; */
 
  
}
.dark-mode .container {
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  margin: auto;
 
  
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}



.upc-box {
  position: relative; 
  box-sizing: border-box;
  background-color: #efedf8;
  border-radius: 4px;
  height: 358px;
  width: 304px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; 
  margin-bottom: 5%;
}
/* .dark-mode, .upc-box {
  position: relative; 
  box-sizing: border-box;
  background-color: #212136;
  border-radius: 4px;
  height: 358px;
  width: 304px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; 
  margin-bottom: 5%;
} */

.upc-title,
.upc-count {
  bottom: 20px; 
  text-align: center;
  font-size: 18px;
  background-color: #efedf8;
  margin-bottom: 10%;

}
/* .dark-mode, .upc-title,
.upc-count {
  bottom: 20px; 
  text-align: center;
  font-size: 18px;
  background-color: #efedf8;
} */
.upc-title {
  background-color: #efedf8;
  margin-bottom: 5%;
}

.upc-count {
  font-size: 32px;
}

#upcboxheader {
  background-color: #efedf8;
}



.upc-image {
  width: 234px; 
  height: 200px; 
  align-self: flex-start; 
  margin-bottom: auto; 
  align-self:center;
  margin-top: 5%;
}

#upcimage1 {
  width: 234px; 
  height: 130px; 
  align-self: flex-start; 
  margin-bottom: auto; 
  align-self:center;
  margin-top: 10%;
}
.homepage-pic {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  height: 736px;
  z-index: -999;
  margin-top: 3%;
}


#homepagetitle {
  font-size: 45px;
  margin-top: -53%;
  text-align: center;

  margin-left: auto;
  
}

#homepagesubtitle {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: rgba(23, 21, 45, 0.4);
  margin-top: 10%;


}

#signupnewsletter {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 99999;
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 999; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

#popupclosebutton {
  color: black;
  background-color: white;
  border: 2px solid #efedf8;
  padding: 2%;
}

#homepageanimationdiv {
  width: 200%;
  align-self: center;
  margin-top: -35%;
  margin-left: -50%;
  max-height: 10%;
  pointer-events: none;
  z-index: -999;
 
}

#homepageanimation {
  /* z-index: -1; */
  margin-top: -20%;
  z-index: -999;
}

.dark-mode #left-top-upc{

  background-color: #212136;

}
.dark-mode #upcboxesupdated {
  background-color: #212136;
}

.dark-mode #left-bottom-upc{

  background-color: #212136;

}
.dark-mode #right-bottom-upc{

  background-color: #212136;

}
.dark-mode #right-top-upc{

  background-color: #212136;

}

.dark-mode #upcboxheader, .dark-mode #upc-countID {
  background-color: #212136;
  color: white;
}

 /* .dark-mode, .upc-title,
.upc-count {
  bottom: 20px;
  text-align: center;
  font-size: 18px;
  background-color: #efedf8;
} */

/* #rwabanner {
  text-align: center;
  background: linear-gradient(90deg, rgba(236, 113, 199, 1) 0%, rgba(255, 255, 255, 1) 100%);  height: 200px;
  font-size: 40px;
  color: rgb(5, 5, 5);
  padding-top: 2%;
  padding-bottom: 2%;
  
  
}

#joinusbutton {
  background-color: rgb(236, 113, 199);
  border-radius: 10px;
  margin-left: 5%;
  color: black;
  padding: 2%;
} */

#rwabanner {
  text-align: center;
  background: linear-gradient(90deg, #7d7aff 0%, rgba(255, 255, 255, 1) 100%);
  /* background-color: #7d7aff; */
  height: 150px;
  font-size: 30px;
  color: rgb(5, 5, 5);
  /* padding-top: 2%; */
  /* padding-bottom: 2%; */
  animation: pulsate 2s infinite;
  align-items: center;
  padding: 2%;
}

/* @keyframes pulsate {
  0% {
    background: linear-gradient(90deg, rgba(236, 113, 199, 1) 0%, rgba(255, 255, 255, 1) 100%);
  }
  50% {
    background: linear-gradient(90deg, rgba(66, 133, 244, 1) 0%, rgba(255, 255, 255, 1) 100%);
  }
  100% {
    background: linear-gradient(90deg, rgba(236, 113, 199, 1) 0%, rgba(255, 255, 255, 1) 100%);
  }
} */

#joinusbutton {
  background-color: #9470dc;
  border-radius: 10px;
  margin-left: 5%;
  color: rgb(255, 255, 255);
  /* padding: 2%; */
  font-weight: 700;
  /* margin-bottom: 5%; */
  /* margin-top: 1%; */
  padding: 1%;
}


@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
    /* margin-left: 10%; */
    max-width: 100%;
    margin-right: 0;
    overflow-x: hidden;
  }

  .upc-box {
    width: 100%;
   

  }

  .main-content {
    width: 100%;
    max-width: 656px;
    margin-top: -15%;
    /* margin-bottom: -10%; */
  }

#carouselboxupc {
  justify-items: center;
}

  #upcboxcarousel {
    justify-content: center;
    width: 100%;
  }

  /* .main-content{
    max-width: 100%;
    overflow-x: hidden;
  } */

  .homepage-pic {
    max-width: 100%;
  }

.upc-image #upcimage1 {
  height: 130px;
}

  .popup-overlay {
    /* position: fixed; */
    /* top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 999; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20%;
    margin-right: 30%;
    overflow-x: hidden;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 70%;
    max-height: 80%;
    margin-right: 10%;
    /* overflow-y: auto; */
    overflow-x: hidden;
  }

  #newsletteriframe {
    width: 100%;
    height: 400px;
    overflow-x: hidden;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  #popupclosebutton {
    color: black;
    background-color: white;
    border: 2px solid #efedf8;
    padding: 2%;
  }
  
  #rwabanner {
    text-align: center;
    background: linear-gradient(90deg, #7d7aff 0%, rgba(255, 255, 255, 1) 100%);
    /* background-color: #7d7aff; */
    height: 150px;
    font-size: 25px;
    color: rgb(5, 5, 5);
    /* padding-top: 2%; */
    /* padding-bottom: 2%; */
    animation: pulsate 2s infinite;
    align-items: center;
    padding: 2%;
  }
  


}
