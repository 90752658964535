/* :root {
  --background-color-light: rgba(245, 244, 250, 1);
  --text-color-light: #000000;
  
  --background-color-dark: #282c34;
  --text-color-dark: #ffffff;
}


.App {
  text-align: center;
  background-color: var(--background-color-light);
  color: var(--text-color-light);
}

.dark-mode .App {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#filtermenu {
  margin-top: -10%;
}

@media screen and (max-width: 768px) {

  #navbarlogo {
    margin-left: 0%;
  }


} */

body {
  overflow-x: hidden;
}

:root {
  --background-color-light: rgba(245, 244, 250, 1);
  --text-color-light: #000000;

  --background-color-dark: #282c34;
  --text-color-dark: #ffffff;
}

.App {
  text-align: center;
  background-color: var(--background-color-light);
  color: var(--text-color-light);
}

.dark-mode .App {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--background-color-dark); /* Changed to match dark mode */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; /* Changed to white for better visibility in dark mode */
}

.App-link {
  color: #61dafb;
}


.dark-mode #navbarbutton {
  color: white;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#filtermenu {
  margin-top: -10%;
}

@media screen and (max-width: 768px) {
  #navbarlogo {
    margin-left: 0%;
  }
}
