

.filter-tags {
    display: flex; /* Ensure the tags are displayed as a flex container */
    justify-content: center; /* Center the tags horizontally */
    margin-top: 20px; /* Adjust the top margin as needed */
  }
  
  #filtertags {
    /* Remove unnecessary properties */
    color: black;
    max-width: 100%;
    max-height: 30%;
    font-size: 12px;
  }
  
  .dark-mode #filtertags {
    color: white; /* Adjust color for dark mode if needed */
  }
  
  .tag {
    /* Add margin between tags */
    margin-right: 15px;
  }
  
  .remove-tag {
    /* Style the remove button if needed */
    background-color: red;
    border: 1px solid black;
    color: white;
    padding: 3px 5px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;

  }
  
  .remove-tag:hover {
    background-color: purple; /* Change color on hover if desired */
  }
  
  #removetagbutton {
    margin-left: 5px;
  }