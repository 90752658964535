#categorydiv {
    display: flex;
    flex-direction: column;
    max-width: 80%;
    background-color: white;
    margin: auto;
    /* text-align: center; */
    margin-top: 10%;
    margin-bottom: 5%;
  
}

#categoryname {
    width: 100%;
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
    font-size: 18px;
    font-weight: 700;
    /* text-align: center; */
    /* margin: auto; */
    text-align: center;
}

#categorydescription {
    color: rgba(23, 21, 45, 1.2);
    padding: 5%;
    text-align: center;

}
#subcategorydescription {
    color: rgba(23, 21, 45, 1.2);
    padding-left: 6%;
    padding-right: 6%;
    text-align: left;
    margin-top: 3%;
  

}

#subcategorylist {
  /* padding-left: 5%; */
  margin-top: 3%;
  list-style-type: disc; /* or circle, square, etc. */
  margin-left: 7%;
}

#link {
  text-decoration: underline;
  font-style: italic;
}

#subcategories {
    display: inline-block; 
    margin-left: 10%; 
    padding-left: 0; 
    margin-bottom: 3%;
    font-size: 18px;
    font-weight: 700;
}

#subcategories ul {
    display: inline-block; 
    margin-left: 10%; 
    padding-left: 0; 
  }
  
  #subcategories ul li {
    display: inline; 
    margin-right: 10px; 
    
  }

  #subcategoriesul {
    margin-bottom: 3%;
  }

  #subcategoriesitem {
    color: rgba(23, 21, 45, 0.4);
    margin-left: 10%;

  }

  #projectcarousel {
    margin-bottom: 5%;
    max-width: 90%;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
  }

  #projectcarouselheader {
    text-align: center;
    font-size: 18px;
    margin-bottom: 3%;
  }
  
 .dark-mode #projectcarouselheader {
    text-align: center;
    color: white;
  }

  #divider {
    max-width: 50%;
  }

  /* #categorynamedivider {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;

  } */

  #categorynamedivider {
    width: 80%; /* Adjust width as needed */
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 10px; /* Adjust margin as needed */
    margin-left: auto;
    margin-right: auto;
  }

  #viewmoreofthecard {
    text-align: center;
    margin: auto;
    padding: 2%;
  }

  #categorytable {
    max-width: 80%;
    margin: auto;
  }