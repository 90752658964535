.individual-project {
  display: grid;
  grid-template-rows: repeat(1, 76px);
  margin: 10%;
  background-color: white;
}

.row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  width: 100%;
}

.left-half {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  max-width: 100%;
  height: 100%;
}

#project {
  margin-bottom: 10%;
}

.namebox {
  display: flex;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  /* margin-top: auto; */
  align-items: center;
}

.project-logo-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-logo {
  justify-content: center;
  display: flex;
  align-items: center;
  /* align-items: end; */
  margin: auto;
  margin-right: 20%;
}
.sub-categories-label {
  margin-bottom: 5px;
}

.sub-categories-value {
  margin-bottom: 10px;
}

#aboutbox {
  color: rgba(23, 21, 45, 0.4);
  height: 100%;
  width: 100%;
}

#aboutboxspan {
  color: black;
  font-size: 16px;
  font-weight: 700;
  margin-top: 3%;
  display: flex;
  align-items: center;
  margin-left: 5%;
  margin-bottom: 5%;
}

.box1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5%;
  margin-left: 5%;
  margin-right: 2%;
}

#boxspan {
  color: rgba(23, 21, 45, 0.4);
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
}

#boxdata {
  font-weight: 500; /* Adjust font weight as needed */
}

#chainLogo {
  background-color: white;
  display: flex;
  flex-direction: row;
  /* margin-left: -20%; */

  max-width: 60%;
}

.chain-logo {
  max-width: 100%;
  margin-right: 5px; /* Adjust the margin-right value as needed */
  width: 30px; /* Set a specific width for each logo */
}

#chainLogo:not(:last-child) {
  margin-right: 0; /* Adjust the margin-right value as needed */
}

#chainlogodiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

#discordlogo {
  background-color: white;
  display: inline-block;
margin-right: 1%;}

#twitterlogoaboutpage {
  margin-left: 5%;
  margin-right: 1%;
}

#twitterspace {
  display: flex;
  margin-left: 20%;
  flex-direction: column;
  align-items: center;
}

#twitterspacelogo {
  max-width: 30%;
  text-align: center;
  align-items: center;
}

#lefticon {
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 20%;
  height: 100%;
}
#gobackbutton {
  /* max-width: 0%; */
  max-height: 100%;
  width: 20%;
  margin-right: -15%;
  height: 100%;
}

.box {
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-gap: 5px;
  width: 100%;
}

/* #aboutbox #socialmediaboxdiv {
  max-width: 100%;
} */

#socialmediaboxdiv {
  display: flex;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 5%;
  box-sizing: border-box;
  max-width: 100%;
}

#websiteurl {
  display: inline-block;
  font-weight: 700;
  padding: 1%;
  border-radius: 4px;
  background-color: rgba(245, 244, 250, 1);
}

#websiteurlspan {
  display: flex;
  align-items: center;
  width: 100%;
}

#websiteurlspan .h-5 {
  margin-right: 5px;
}

#aboutbox #aboutsummaryparagraph {
  align-self: start;
  margin-bottom: 0;
  max-width: 80%;
  margin-left: 5%;
}

.row1 {
  height: 100%;
}

@media (max-width: 768px) {
  .row {
    grid-template-columns: repeat(
      1,
      1fr
    ); 
  }
  #aboutsummaryparagraph {
    width: 100%;

    margin-left: auto;
  }
  .namebox {
    font-size: 24px; /* Decrease font size for better mobile readability */
    /* margin: auto; */
  }

  .project-logo {
    margin-right: 10%; /* Adjust margin for better alignment on mobile */
  }

  /* .box1 {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #boxspan {
    color: rgba(23, 21, 45, 0.4);
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    flex: 0 0 auto; 
  } */

  .box1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5%;
    margin-left: 5%;
    margin-right: 2%;
    flex-grow: 1;

  }
  
  #boxspan {
    color: rgba(23, 21, 45, 0.4);
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    font-weight: 500;
  }
  
  #boxspan1 {
    color: rgba(23, 21, 45, 0.4);
    margin-right: 28%;
    display: flex;
    flex-direction: column;
    font-weight: 500;
  }
  
  #boxdata {
    font-weight: 500; /* Adjust font weight as needed */
    right: 0;
    /* flex-grow: 1; */
    /* margin-left: 50%; */
    /* position: relative;
    right: 0; */
    
  }

  #boxdata2 {
    margin-right: -10%;
    margin-left: 25%;
    flex-grow: 2;
  }
  #boxdata1 {
    /* margin-left: 11%; */
    margin-right: -3%;
    flex-grow: 1;
  }

 
  #twitterspace {
    margin-left: 0; /* Reset margin for better alignment on mobile */
  }

}
