.side-menu {
    position: fixed;
    width: 20%;
    top: 70%;
    left: 0;
    transform: translateY(-50%);
    background-color: #fff; /* Adjust background color as needed */
    border-right: 1px solid #ccc; /* Add border for separation */
    padding: 10px;
    max-height: 80vh; /* Limit maximum height for scrolling */
    overflow-y: auto; /* Enable scrolling if content overflows */
  }
  
  .menu-item {
    cursor: pointer;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .menu-item:hover {
    background-color: #f0f0f0; /* Change background color on hover */
  }
  
  .menu-item h4, .menu-item p {
    margin: 0;
  }
  