

.main-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;
  }
  
  .content-container {
    display: flex;
  }
  
  .partnerships-box,
  .users-box {
    width: 60%;
    height: 90%;
    margin-right: 20px;
    padding: 20px;
  }
  
  .partnerships-box {
    margin-right: 10%;
  }
  
  /* .users-box {
    background-color: #e0e0e0;
  }
   */
   #homepageherotitle {
    font-size: 45px;
    line-height: 45px;
    margin-bottom: 16px;
    text-align: center;
    align-items: center;  
    padding: 5%; 
    margin-top: -5%; 
  }

  @media only screen and (max-width: 768px) {
    .main-container {
      flex-direction: column;
      align-items: center; /* Center horizontally */
      justify-content: center; /* Center vertically */
    }
  
    .content-container {
      flex-direction: column;
      align-items: center; /* Center horizontally */
    }
  
    .partnerships-box,
    .users-box {
      width: 80%;
      margin: 10px 0;
    }
  }
  
  